<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <template v-if="waitingOnValidate">
          <h2 class="mb-1">
            Aguarde um momento ⏱
          </h2>
          <p class="mb-3">
            Estamos validando sua conta. Você será redirecionado em breve.
          </p>
          <small>Não feche essa guia do navegador.</small>
        </template>

        <template v-if="errorOnValidate">
          <h2 class="mb-1">
            Ocorreu um problema ❌
          </h2>
          <p class="mb-3">
            Aconteceu um erro ao tentar validar sua conta, retorne ao seu email e tente novamente.
          </p>
          <p class="mb-3">
            <b-button
              variant="primary"
              @click="() => this.$router.push({ path: '/login' })"
            >
              Ir para o Login
            </b-button>
          </p>
        </template>

        <template v-if="successOnValidate">
          <h2 class="mb-1">
            Tudo pronto 🚀
          </h2>
          <p class="mb-1">
            Seu usuário foi validado com sucesso, você será redirecionado para a tela de login em 5 segundos
          </p>
          <p class="mb-3">
            <b-button
              variant="primary"
              @click="() => this.$router.push({ path: '/login' })"
            >
              Ir para o Login
            </b-button>
          </p>
        </template>

        <b-img
          fluid
          :src="imgUrl"
          alt="Validando sua conta"
        />
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg, BButton,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

import useJwt from '@/auth/jwt/useJwt'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ValidateAccountPage',
  components: {
    VuexyLogo,
    BLink,
    BImg,
    BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      waitingOnValidate: true,
      errorOnValidate: false,
      successOnValidate: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
    const { token } = this.$route.params

    useJwt.validateAccount(token)
      .then(response => {
        if (response.data.successfullyProcessed) {
          this.waitingOnValidate = false
          this.successOnValidate = true

          setTimeout(() => {
            this.$router.push({ path: '/' })
          }, 5000)
          return response
        }
        this.waitingOnValidate = false
        this.errorOnValidate = true
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opss..',
            icon: 'Erro',
            variant: 'danger',
            text: response.data.errors[0],
          },
        })
        return response
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opss..',
            icon: 'Erro',
            variant: 'danger',
            text: error.response.data.errors[0],
          },
        })
        return error
        // this.$router.push({ path: '/' })
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
